import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";

Vue.use(Router);

export default new Router({
  // When using history mode, the URL will look "normal," e.g. http://oursite.com/user/id. Beautiful!
  // Here comes a problem, though: Since our app is a single page client side app, without a proper server configuration,
  // the users will get a 404 error if they access http://oursite.com/user/id directly in their browser. Now that's ugly.
  // Not to worry: To fix the issue, all you need to do is add a simple catch-all fallback route to your server. If the URL doesn't match any static assets,
  // it should serve the same index.html page that your app lives in. Beautiful, again!
  // https://stackoverflow.com/questions/47677220/vuejs-history-mode-with-github-gitlab-pages
  // https://github.com/rafrex/spa-github-pages

  // ATTENTION hashtag removal works but on dist routes stop working it needs special server config OR PRERENDERING
  // https://github.com/chrisvfritz/prerender-spa-plugin/tree/e2c49428e0efab5a672f421a3a33da45fc345710/examples/vue2-webpack-router
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      // Not Lazy Loaded
      component: Home
    },
    {
      path: "/about",
      name: "About",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/About.vue")
    },
    {
      path: "/contact",
      name: "Contact",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/Contact.vue"),
      children: []
    }, // catch all use case
    {
      path: "/contact/success",
      name: "Success",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/SubmissionSuccess.vue")
    },
    {
      path: "/contact/error",
      name: "Error",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/SubmissionFailure.vue")
    },
    {
      path: "/faq",
      name: "F.A.Q.",
      component: () => import(/* webpackChunkName: "about" */ "./views/FAQ.vue")
    },
    {
      path: "/preset-installation-tutorials",
      name: "Tutorial",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/Tutorials.vue")
    },
    {
      path: "/hire-me-for-a-solo",
      name: "Solo",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/Solo.vue")
    },
    {
      path: "/bias-preset-importer-tool",
      name: "Bias Importer Tool",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/Importer.vue")
    },
    {
      path: "/get-the-source-code",
      name: "Source Code",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/SourceCode.vue")
    },
    {
      path: "/preset/:id", // this doesn't work in history mode ( all it needed was a "/" in front of the script paths to search in root, instead of the preset folder)
      name: "Preset",
      // props: true, // fucks up the scripts (partcls main etc)
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/Preset.vue")
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // when you use the history back and forward browser keys yuo also save your sroll coordinates
    } else {
      return {
        // goes to top of the page for all route navigations
        x: 0,
        y: 0
      };
    }
  }
});
