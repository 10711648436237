<template>
    <div class="customNav">
        <button class="button first">◀◀</button>
        <button class="button prev">◀</button>
        <button class="button next">▶</button>
        <button class="button last">▶▶</button>
    </div>
</template>

<script>
export default {
  name: "owlNavigation"
};
</script>
