<template>
    <!-- to tell view not to render an already rendered component  -->
    <!-- data-server-rendered="true" -->
    <!-- There is another id="app" in index.html -->
    <div id="app">
        <!-- ATTENTION the router automatically loads the Home.vue view  -->
        <router-view />
        <!-- ATTENTION these run only once (if the user switches views nothing happens (App.vue is loaded only once)) (sometimes they don't load as fast) -->
        <!-- <script2>console.log("App.vue loaded")</script2> -->
    </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      meta: [
        {
          // we use vmid to declare that this can be overited by child components
          vmid: "og:image",
          property: "og:image",
          content: "https://i.imgur.com/1rWkX0D.png"
        },
        {
          name: "twitter:card",
          content: "ToneGod Presets"
        }
      ]
    };
  }
};
</script>

// ATTENTION GLOBAL SASS
<style lang="scss" src="@/assets/sass/main.scss">
</style>
