import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import Meta from "vue-meta";
Vue.use(Meta, {
  keyName: "metaInfo", // the component option name that vue-meta looks for meta info on.
  attribute: "data-vue-meta", // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: "data-vue-meta-server-rendered", // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: "vmid" // the property name that vue-meta uses to determine whether to overwrite or append a tag
});

// it is possible to test analytics from localhost (make sure to disable ublock)
import VueAnalytics from "vue-analytics";
Vue.use(VueAnalytics, {
  id: "UA-110319659-3",
  router
});

import VueAWN from "vue-awesome-notifications";
let options = {
  duration: 3000,
  position: "bottom-left",
  animationDuration: 500,
  labels: {
    success: ""
  },
  icons: {
    enabled: false
    // success: "fa-check-circle"
  }
};
Vue.use(VueAWN, options);

import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
  loading: "/images/loading.svg"
});

import LoadScript from "vue-plugin-load-script"; //solved the problem when switching views with granim, carousel, particles
Vue.use(LoadScript);

// FIXME: Do I really need this?
import VS2 from "vue-script2"; //VS2 is kinda useless you can simply use "require("myscript.js")" in mounted()
Vue.use(VS2);

const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo); // id="bias"    href="/about/#bias"

// npm install vue-youtube
import VueYoutube from "vue-youtube";
Vue.use(VueYoutube);

Vue.config.productionTip = false;
Vue.config.devtools = true;

// Stuff that is loaded here is available globally
// Mixins are a flexible way to distribute reusable functionalities for Vue components.
// the method refreshScripts is available globally
Vue.mixin({
  // ATTENTION FINALLY with "npm install --save-dev vue-plugin-load-script" granim, partcls don't fuck up when switching views
  methods: {
    refreshScripts: function(prefix, scripts) {
      for (let s in scripts) {
        // console.log("UNLOADING... " + prefix + scripts[s]);
        this.$unloadScript(prefix + scripts[s])
          .then(() => {
            // Script was unloaded successfully
          })
          .catch(() => {
            // Script couldn't be found to unload; make sure it was loaded and that you passed the same URL
          });

        // console.log("LOADING... " + prefix + scripts[s]);
        this.$loadScript(prefix + scripts[s])
          .then(() => {
            // Script was unloaded successfully
          })
          .catch(() => {
            // Script couldn't be found to unload; make sure it was loaded and that you passed the same URL
          });
      }
    },
    goTo: function(link) {
      window.open(link, "_blank");
    },
    youtubeEmbed: function(link) {
      link = link.replace("watch?v=", "embed/");
      link = link.concat(
        "?enablejsapi=1&showinfo=0&origin=https://www.tonegaod.art"
      ); // Makes no difference
      // console.debug(link);
      return link;
    },
    getYoutubeIdFromUrl: function(url) {
      return this.$youtube.getIdFromUrl(url);
    },
    soundcloudEmbed: function(link) {
      let before = "https://w.soundcloud.com/player/?url=";
      let params =
        "&color=1fdfca&auto_play=false&hide_related=true&show_comments=false&show_user=true&show_reposts=false&show_teaser=true";
      return before + link + params;
    }
  }
});

// You will need to add v-vpshow directive to the elements you want to animate when they become visible in the viewport.
// check the classes  "enter" and "before-enter" in _page.scss
Vue.directive("vpshow", {
  inViewport(el) {
    var rect = el.getBoundingClientRect();
    return !(
      rect.bottom < 0 ||
      rect.right < 0 ||
      rect.left > window.innerWidth ||
      rect.top > window.innerHeight
    );
  },

  bind(el, binding) {
    el.classList.add("before-enter");
    el.$onScroll = function() {
      if (binding.def.inViewport(el)) {
        el.classList.add("enter");
        el.classList.remove("before-enter");
        binding.def.unbind(el, binding);
      }
    };
    document.addEventListener("scroll", el.$onScroll);
  },

  inserted(el) {
    el.$onScroll();
  },

  unbind(el) {
    document.removeEventListener("scroll", el.$onScroll);
    delete el.$onScroll;
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
