<template>
  <fancyBackground>
    <!-- ATTENTION if you are going to use a single slot you must wrap the contents in a div -->
    <div>
      <headerMain />
      <slider />
      <footerMain />
    </div>
  </fancyBackground>

  <!-- ATTENTION these scripts run every time the user switches views -->
  <!-- <script2>console.log("Home.vue loaded")</script2>     -->
</template>

<script>
// @ is an alias to /src
import fancyBackground from "@/components/Background.vue";
import headerMain from "@/components/HeaderMain.vue";
import footerMain from "@/components/Footer.vue";
import slider from "@/components/Slider.vue";
// ATTENTION runs too early
// import "@/assets/js/granim.js";

export default {
  name: "home",
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Home",
      // all titles will be injected into this template
      titleTemplate: "%s | ToneGod",
      meta: [
        {
          name: "description",
          content: this.description
        },
        {
          property: "og:title",
          content: this.title
        },
        {
          property: "og:description",
          content: this.description
        },
        {
          property: "og:url",
          content: "https://www.tonegod.art/"
        }
      ],
      link: [{ rel: "canonical", href: "https://www.tonegod.art/" }]
    };
  },
  data() {
    return {
      title: "Home",
      description: "Presets for Helix, Hx Stomp, Bias Amp and Bias FX."
    };
  },
  mounted() {
    //ATTENTION jquery is available and works even after switching views ()
    // $("#main").hide();
    // $(document).ready(function(){
    // console.log("test from jquery");
    // });
    // It can't find "js/util.js"
    // require("@/assets/js/util.js");
  },
  components: {
    fancyBackground,
    headerMain,
    footerMain,
    slider
  }
};
</script>
