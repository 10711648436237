<template>

  <div id="particles-js" class="canvas-interactive-wrapper animated fadeIn">
    <!-- <p>Backgound Test</p> -->
    <canvas id="canvas-interactive-granim" v-once></canvas>
    <!-- // FIXME: Slow render on mobile when fast scrolling. Removing granim and partcls results in a white glitch   -->
    <!-- FIXME: this component is not responsible for the netlify reload bug -->
    <div id="everything">
      <div id="wrapper" class="cta-wrapper is-preload">
        <!-- ATTENTION child of the "fancyBackground" element is injected in the slot (Must be one child or wrapped in a div)-->
        <slot></slot>

      </div>

      <!-- OLD MENU Mystery bug -->
      <nav id="menu">
        <h2>Menu</h2>
        <ul>
          <li>
            <router-link to="/">Home</router-link>
          </li>
          <li>
            <router-link to="/about/">About</router-link>
          </li>
          <li>
            <router-link to="/faq/">F.A.Q.</router-link>
          </li>
          <li>
            <router-link to="/contact/">Contact</router-link>
          </li>
        </ul>
        <br>
        <ul>
          <li>
            <router-link to="/bias-preset-importer-tool/">Bias Importer Tool</router-link>
          </li>
          <li>
            <router-link to="/preset-installation-tutorials/">Preset Installation Tutorials</router-link>
          </li>
          <li>
            <router-link to="/hire-me-for-a-solo/">Hire me for a Solo</router-link>
          </li>
          <li>
            <router-link to="/get-the-source-code/">Gimme site's source code</router-link>
          </li>
        </ul>

      </nav>

      <div id="header">
        <nav>
          <ul>
            <li><a href="#menu">Menu</a></li>
          </ul>
        </nav>
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable */
  export default {
    name: "fancyBackground",
    components: {},
    data() {
      return {
        selfDestructKey: 0,
        times: [],
        fps: 60,
        fpsSamples: 3,
        fpsSamplesCount: 0,
        threshold: 2
      };
    },
    beforeMount() {
      // this.fpsTest();

    },
    mounted() {
      // we want to start granim partcls and main in that order as soon as possible -->
      // there is an onload in etc that removes the 'is-preload' class -->
      // etc is Responsible for sticky menu on top right and the display:none of the logo-->
      this.granim();
      this.initParticles(); // optionally you can run the fpsTest test first in beforeMounted() and comment granim and particles
      // this.etc();// ATTENTION if you use this in classic mode, the menu will only open once. If you use this in history mode it will open only once, unless the screen flashes
      this.$loadScript("/js/etc.js"); // ATTENTION if you run this.etc(); in classic mode, not history, the menu will work only once, this solves the problem. menu mustn't be unloaded
      // this.analytics(); // analytics is taken care of by main.js
      // ATTENTION carousel is loaded in Slider.vue -->
    },
    methods: {
      granim() {
        var granimInstance = new Granim({
          element: "#canvas-interactive-granim",
          name: "interactive-gradient",
          elToSetClassOn: ".canvas-interactive-wrapper",
          direction: "left-right",
          isPausedWhenNotInView: true,
          stateTransitionSpeed: 500, // speed between states you only have one state it doesn't do anything
          states: {
            "default-state": {
              gradients: [
                ["#E1EEC3", "#FDAADC"],
                ["#00F260", "#0575E6"]
              ],
              transitionSpeed: 15000 //speed between gradients
            }
          }
        });
        console.log("granim loaded");
      },
      initParticles() {
        window.particlesJS("particles-js", {
          particles: {
            number: {
              value: 30,
              density: {
                enable: true,
                value_area: 800
              }
            },
            color: {
              value: "#ffffff"
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000000"
              },
              polygon: {
                nb_sides: 5
              },
              image: {
                src: "img/github.svg",
                width: 100,
                height: 100
              }
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false
              }
            },
            size: {
              value: 7.891600969088593,
              random: true,
              anim: {
                enable: true,
                speed: 10,
                size_min: 0.1,
                sync: false
              }
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: "#ffffff",
              opacity: 0.4,
              width: 1
            },
            move: {
              enable: true,
              speed: 2,
              direction: "none",
              random: false,
              straight: false,
              out_mode: "bounce",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
              }
            }
          },
          interactivity: {
            detect_on: "window",
            events: {
              onhover: {
                enable: true,
                mode: "grab"
              },
              onclick: {
                enable: true,
                mode: "push"
              },
              resize: true
            },
            modes: {
              grab: {
                distance: 274.12508410655977,
                line_linked: {
                  opacity: 0.5
                }
              },
              bubble: {
                distance: 400,
                size: 40,
                duration: 2,
                opacity: 8,
                speed: 3
              },
              repulse: {
                distance: 200,
                duration: 0.4
              },
              push: {
                particles_nb: 2
              },
              remove: {
                particles_nb: 2
              }
            }
          },
          retina_detect: true
        });
        console.log("particles.js config loaded (callback)");
      },
      // analytics() {
      //   // Unused old method
      //   window.dataLayer = window.dataLayer || [];

      //   function gtag() {
      //     dataLayer.push(arguments);
      //   }
      //   gtag('js', new Date());

      //   gtag('config', 'UA-110319659-3');
      //   console.log("analytics loaded");
      // },
      fpsTest() {
        window.requestAnimationFrame(() => {
          const now = performance.now();
          while (this.times.length > 0 && this.times[0] <= now - 1000) {
            this.times.shift();
          }
          this.times.push(now);
          this.fps = this.times.length;
          //------------------------- Checks if the device can handle particles
          this.fpsSamplesCount++;
          if (this.fpsSamplesCount <= this.fpsSamples) {
            this.fpsTest(); // RECURSION WARNING
          } else if (
            this.fpsSamplesCount >= this.fpsSamples &&
            this.fps > this.threshold
          ) {
            console.debug("PASSED FPS TEST");
            this.granim();
            this.initParticles();
          }
          // console.debug(this.fps + " " + this.fpsSamplesCount);
        });
      }
    }
  };
</script>

<style lang="scss">
  // remove empty style tags, they take up spave in the header for no reason
  // I am not moving this to a separate sass file to remember this method exists.
  #particles-js {
    // I added this animation to make the fpsTest smoother
    animation-duration: 2150ms;
  }
</style>