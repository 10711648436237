<template>
    <footer id="footer" v-vpshow>
        <div class="inner">
            <section>
                <h2>Keep In Touch</h2>
                <ul class="icons">
                    <li><a href="https://www.youtube.com/channel/UCzg4SWk_mrpKA31s2dT5Clw" class="icon" target="_blank"><img src="/images/youtube_icon.svg"></a></li>
                    <li><a href="https://www.facebook.com/jimmm.vasil.3" class="icon" target="_blank"><img src="/images/facebook_icon.svg" /></a></li>
                    <li><a href="https://www.instagram.com/dimitrisvasil/" class="icon" target="_blank"><img src="/images/instagram_icon.svg" /></a></li>
                    <li><a href="https://github.com/catman85" class="icon" target="_blank"><img src="/images/octocat_icon.svg"></a></li>
                    <!-- <li><a href="https://jimmypickboy.wordpress.com/" class="icon" target="_blank"><img src="/images/wordpress_icon.svg"></a></li> -->
                    <li><a href="https://soundcloud.com/ocarinainmypants" class="icon" target="_blank"><img src="/images/soundcloud_icon.svg"></a></li>

                    <!-- <li><a href="#" class="icon style2 "><img src=""></a></li> -->
                </ul>
            </section>
            <ul class="copyright">
                <li>&copy; ToneGod. All rights reserved</li>
                <li>Design & Development by: <a href="https://github.com/catman85" target="_blank">Dimitris Vasil</a></li>
                <li><a href="https://jimmypickboy.wordpress.com/" target="_blank">Old Site</a></li>
                <li><a href="/about/#gdpr" target="_blank">GDPR Compliance</a></li>
                <li><a href="/about/#legal" target="_blank">Legal</a></li>
            </ul>
        </div>
    </footer>
</template>

<script>
export default {
  name: "footerMain"
};
</script>
