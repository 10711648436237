<template>
  <div id="main">
    <div class="inner">
      <!-- onSubmit doesn't exist. everytime the user presses something the query is sent again -->
      <form v-on:submit.prevent="onSubmit" v-vpshow v-once>
        <div class="barsGroup">

          <!-- TODO: remove console commands in production -->

          <!-- If you want to listen to a native event on the root element of a component, you have to use the .native modifier for v-on  v-on:click.native="functionName"-->
          <dropdown ref="dropGenre" v-on-clickaway.native="closeMenus" :options="genresList" :selected="genre"
            v-on:updateOption="onSelectGenre" :placeholder="'Select an Item'">
          </dropdown>
          <dropdown ref="dropOrder" v-on-clickaway.native="closeMenus" :options="orderBy" :selected="order"
            v-on:updateOption="onSelectOrder" :placeholder="'Select an Item'">
          </dropdown>
          <input type="text" v-model="query" id="search-query" placeholder="Search For ...">

        </div>

        <div id="togglesContainer">
          <!-- multiple instances of span -->
          <!-- class="animated zoomIn" -->
          <template>
            <span id="toggle" v-for="(item, i) in switches" :key="i">
              <toggle-button :value="item.value" :color="item.color" :sync="true" :labels="item.label" :key="i"
                @change="onUpdateSwitchValue(i)" :width="item.width" :height="40" />
            </span>
          </template>
        </div>

      </form>
      <!-- FIXME: this component is not responsible for the netlify reload bug -->
      <!-- // FIXME: temporary hack for the toggles. the fucking togggles escape 2 divs and the form? why??? -->
      <div class="customNav"></div>

      <!-- for carousel pages numbering -->
      <div class="customNav" v-vpshow>
        <span id=info></span>
      </div>
      <!-- The problem is that v-for is executed before v-if. Use template tag to overcome this-->
      <!-- we set :nav="false" and :dots="false" because we will use the settings in carousel.js -->

      <!-- for small devices we show one row -->
      <template v-if="isSmall">
        <carousel class="container" :key="selfDestructKey" :nav="false" :dots="false" :lazyLoad="true" v-vpshow>
          <card v-for="(post,i) in filteredPosts" :key="i" :id=post.id :category=post.category[0]
            :category2=post.category[1] :data-image=post.image>
            <h1 slot="header">{{post.title}}</h1>
            <p slot="content">{{post.short_description}}</p>
          </card>
        </carousel>
      </template>
      <!-- for other devices we show 2 rows -->
      <template v-else>
        <carousel class="container" :key="selfDestructKey" :nav="false" :dots="false" :lazyLoad="true" v-vpshow>
          <div v-for="(pair,i) in pairs" :key="i">
            <card v-for="(post,j) in pair" :key="j" :id=post.id :category=post.category[0] :category2=post.category[1]
              :data-image=post.image v-once>
              <h1 slot="header">{{post.title}}</h1>
              <p slot="content">{{post.short_description}}</p>
            </card>
          </div>
        </carousel>
      </template>

      <owl-navigation v-vpshow />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
  import Vue from "vue";
  import owlNavigation from "@/components/OwlCustomNavigation.vue";
  import card from "@/components/Card.vue";
  import dropdown from "@/components/Dropdown.vue";
  import ToggleButton from "vue-js-toggle-button";
  import carousel from "vue-owl-carousel";
  import {
    mixin as clickaway
  } from 'vue-clickaway';
  import {
    data,
    toggles,
    genres
  } from "@/assets/data.js";
  Vue.use(ToggleButton);

  export default {
    name: "slider",
    components: {
      owlNavigation,
      carousel,
      dropdown: dropdown,
      card
    },
    mixins: [clickaway],
    created() {
      window.addEventListener("resize", this.handleResize());
    },
    destroyed() {
      window.removeEventListener("resize", this.handleResize());
    },
    beforeMount() { // Unused
    },
    mounted() {
      //grab stuff from the URL
      //toggle the switches using get parameters
      // http://localhost:8080/?free=true&helix=true&free vst=true&bias amp=true
      let query = this.$route.query;//FIXME: Do I really need this?
      for (let q in query) {
        for (let s in this.switches) {
          let label = this.switches[s].label.checked.toLowerCase();
          if (q.toLowerCase() == label) {
            this.switches[s].value = this.toBoolean(query[q]);
          }
        }
      }
    },
    data() {
      return {
        toggled: false,
        unlocked: false,
        posts: data,
        switches: toggles,
        query: "",
        selfDestructKey: 0,
        selectedOrderType: "",
        selectedGenre: "",
        genresList: genres,
        orderBy: [{
            name: "Popularity ↘️"
          },
          {
            name: "Price ↘️"
          },
          {
            name: "Default"
          }
        ],
        genre: {
          name: "All Genres"
        },
        order: {
          name: "Order By"
        },
        big: false,
        medium: false,
        small: false,
        images: {
          // nocturnus: require('@/assets/images/pic01.png')
        },
        height: screen.height
      };
    },
    methods: {
      handleResize() {
        // this.height = window.screen.height;
        // this.height = document.height; broken
        this.height = screen.height;
        // this.height = $(document).height();
        if (this.height <= 740) {
          this.small = true;
          this.medium = false;
          this.big = false;
        } else {
          this.medium = true;
          this.small = false;
          this.big = false;
        }

      },
      onSelectOrder(payload) {
        this.selectedOrderType = payload.name;
        //then computed filteredPosts runs
      },
      onSelectGenre(payload) {
        this.selectedGenre = payload.name;
        //then computed runs
      },
      closeMenus() {
        // console.log(this.$refs.dropGenre.showMenu);
        // we use a slight delay on the toggle function of the dropdown component
        // otherwise the menu closes as soon as it opens
        if (this.$refs.dropGenre.showMenu) {
          this.closeDropGenre();
        }
        if (this.$refs.dropOrder.showMenu) {
          this.closeDropOrder();
        }
        // this.closeDropGenre();
        // this.closeDropOrder();
      },
      closeDropGenre: function (event) {
        // calling a components inner function by referencing it with ref="compName"
        this.$refs.dropGenre.closeMenu();
        // this.$refs.dropOrder.toggleMenu();
        console.log("closing Genre...");
      },
      closeDropOrder() {
        this.$refs.dropOrder.closeMenu();
        console.debug("closing Drop...");
      },
      onUpdateSwitchValue(i) {
        this.switches[i].value = !this.switches[i].value;
        this.$ga.event({
          eventCategory: "Toggle",
          eventAction: "Switch",
          eventLabel: this.switches[i].label.checked
        });
      },
      forceRerender() {
        // ATTENTION this runs when the filters update because the cards escape the owl-stage
        // Runs only once in the prerendered version as it should when the page loads and every time we tweak a filter
        this.selfDestructKey += 1;
        this.carousel();
        // let scripts = ["carousel.js"];
        // this.refreshScripts("/js/", scripts); // if you use this, carousel.js is loaded twice in the prerendered version, mystery. The carousel is not called the second time by refreshScripts... mystery

      },
      toBoolean(b) {
        switch (b.toLowerCase()) {
          case "true":
            return true;
          case "t":
            return true;
          case "1":
            return true;
          case "0":
            return false;
          case "false":
            return false;
          case "f":
            return false;
          default:
            console.debug("??? What Boolean is: " + b);
        }
      },
      carousel() {
        /* eslint-disable no-undef */
        $(document).ready(function () {
          var owl = $(".owl-carousel");
          owl.owlCarousel({
            // loop:true,
            // margin:10,
            nav: true,
            // navText: ["◀","▶"],
            navContainer: "customNav",
            dots: false,
            lazyLoad: true,
            // onDragged: callback,
            // onInitialized: navHandler,//ATTENTION not needed
            onChanged: navHandler,
            // onRefreshed: navHandler,
            responsive: {
              0: {
                items: 1,
                slideBy: 1
              },
              600: {
                items: 2,
                slideBy: 2
              },
              1000: {
                items: 3,
                slideBy: 3
              }
            }
          });
          // Custom Navigation Events
          $(".first").click(function () {
            owl.trigger("to.owl.carousel", 0);
          });
          $(".next").click(function () {
            owl.trigger("next.owl.carousel");
          });
          $(".prev").click(function () {
            owl.trigger("prev.owl.carousel");
          });
          $(".last").click(function () {
            owl.trigger("to.owl.carousel", $(".owl-item").length);
          });
          console.log("Carousel Loaded");
        });

        // for disabling buttons and pagination, runs onChanged
        function navHandler(event) {
          // var element   = event.target;         // DOM element, in this example .owl-carousel
          // var name      = event.type;           // Name of the event, in this example dragged
          // var namespace = event.namespace;      // Namespace of the event, in this example owl.carousel
          // var columns = event.item.count;       // Number of columns (columns) this is problematic it doesn't go lower than 2
          // Provided by the navigation plugin
          // var pages     = event.page.count;     // Number of pages
          // var page      = event.page.index;     // Position of the current page always -1???

          var columns;
          if (screen.height <= 740) {
            //one card per column
            columns = $(".card").length;
          } else {
            // two cards per column
            columns = Math.ceil($(".card").length / 2);
          }

          var position = event.item.index + 1; // Position of the current item

          var size = event.page.size; // Number of columns per page

          var positions = Math.ceil(columns / size); //Number of possible positions

          if (position == 1 && positions > 1) {
            $(".prev,.first").addClass("disabled");
            $(".next,.last").removeClass("disabled");
          } else if (position == columns - (size - 1) && positions > 1) {
            $(".next,.last").addClass("disabled");
            $(".prev,.first").removeClass("disabled");
          } else if (1 < position && position < columns && positions > 1) {
            $(".next,.last").removeClass("disabled");
            $(".prev,.first").removeClass("disabled");
          } else if (positions == 1) {
            $(".prev,.first").addClass("disabled");
            $(".next,.last").addClass("disabled");
          } else {
            positions++; //0
            columns = columns + size; // Empty carousel
            $(".prev,.first").addClass("disabled");
            $(".next,.last").addClass("disabled");
          }

          if ((columns - size + 1) == -1 || (columns - size + 1) == 0) { // getting a 1/-1 sometimes
            size = 0;
            columns = 0;
          }
          $("#info").text((position) + " / " + (columns - size + 1));

          // console.debug("spin");
          // console.debug("max columns that can fit in a page: " + (size));
          // console.debug("position: " + position);
          // console.debug("columns: " + (columns));
          // console.debug("possible positions: " + (positions));
          // console.debug("___________");
        }
      }
    },
    computed: {
      //these methods run everytime the variables inside them change
      pairs() {
        let p = [],
          copy = this.filteredPosts;
        while (copy.length > 0) p.push(copy.splice(0, 2));
        return p;
      },
      triples() {
        // unnesasary
        let p = [],
          copy = this.filteredPosts;
        while (copy.length > 0) p.push(copy.splice(0, 3));
        return p;
      },
      filteredPosts() {
        //Search
        var filteredPosts = this.posts.filter(post => {
          return post.title.toLowerCase().includes(this.query.toLowerCase());
        });


        // Genre Dropdown
        filteredPosts = filteredPosts.filter(post => {
          if (this.selectedGenre != "All Genres") {
            return post.genre.includes(this.selectedGenre);
          } else {
            return post;
          }
        });

        // Order Types
        if (this.selectedOrderType == "Popularity ↘️") {
          filteredPosts = filteredPosts.sort(function (a, b) {
            return b.popularity - a.popularity;
          });
        } else if (this.selectedOrderType == "Price ↘️") {
          filteredPosts = filteredPosts.sort(function (a, b) {
            return b.price - a.price;
          });
        }

        // Toggles
        for (let i = 0; i < this.switches.length; i++) {
          // if a switch is turned on
          if (this.switches[i].value) {
            filteredPosts = filteredPosts.filter(post => {
              // Check if the toggle.label.checked property matches anything from the post's categories
              for (let j = 0; j < post.category.length; j++) {
                //this runs 1 or 2 times or even more in the future
                if (
                  post.category[j]
                  .toLowerCase()
                  .trim()
                  .split(" ")
                  .join("") ==
                  this.switches[i].label.checked
                  .toLowerCase()
                  .trim()
                  .split(" ")
                  .join("")
                ) {
                  return post; //append this post to the filtered posts
                }
              }
            });
          } // if all the switches are turned off no filtering happens
        }
        // Runs only once in the prerendered version as it should when the page loads and every time we tweak a filter
        this.forceRerender();
        return filteredPosts;
      },
      isSmall() {
        return this.small;
      },
      isMedium() {
        return this.medium;
      }
    },
    watch: {}
  };
</script>
