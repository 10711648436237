// {
//   id: "lead-tone",
//   meta_description: "Djent Positive Grid Preset Free Download",
//   title: "This is soWme log detail for log 1",
//   short_description: "An awesome tone",
//   image: "https://i.imgur.com/SljclfG.png",
//   description:
//     "THis tone was inspired bu <br> blahblha oiagh piasgoiyas [iasg<br> [oiasg oiasg dgkaag asgiuhy alsghasgia asg asgoaisg a ag ag ",
//   youtube: [
//     "https://www.youtube.com/watch?v=LYtiDCXLAcQ",
//     "https://www.youtube.com/watch?v=J7i9vOgpenA"
//   ],
//   descriptionMid:
//     "description in the <b>middle</b> <br><br><img src='https://i.imgur.com/SljclfG.png' /><br>",

//   soundcloud: [
//     "https://soundcloud.com/xvnnieclvus/break-the-law-feat-iamblaize-prod-cleetus-fister",
//     "https://soundcloud.com/ocarinainmypants/monuments-stygian-blue-guitar-tone-bias-fx-helix"
//   ],
//   descriptionBot: "description <b>Bottom</b>",
//   paypal: [
//     "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=SLQ6AQXJ9RJLE",
//     "https://www.facebook.com",
//     "https://www.google.com"
//   ],
//   buttonDescription: ["buy one", "+ Djent, Thall Preset", "+ Thall Preset"],
//   category: ["hELiX", "free vSt"],
//   popularity: 2,
//   genre: "Djent",
//   price: 5
// },

/* eslint-disable */
const data = [
  {
    id: "helix-deftones-ceremony",
    meta_description: "Deftones Heavy Rhythm Chugg Amp Rig Line 6 Helix .hlx Preset",
    title: "Deftones Ohms Line 6 Helix",
    image: "https://i.imgur.com/EQTtyUB.jpg",
    short_description: "",
    description: "The Nu Metal Kings are back in action with a new, crushing album, Ohms. "+
      "I couldn't hold myself from making a cover of 'Ceremony' after the first listen. "+
      "I used Line 6 Helix to craft my sound.",
    youtube: ["https://www.youtube.com/watch?v=Ga3SLjaNcH0"],
    descriptionMid: 
      "This preset features 3 snapshots. One for the intro. One for the main bridge. And one for the ambient clean part."+
      " You can use the snapshot index envelope in reaper, that the helix plugin provides, to automate the switching.<br><br>"+
      " <img src='https://i.imgur.com/AsGQRDW.png'/><br> "+
      "The heart of this preset is a high quality impulse response, which is included in the package.<br>"+
      "(96khz Quality - 2048 IR Block) <br><br>"+
      "<a href='https://www.deftones.com/'>Support</a> the band", 
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=HQ8ZKENTY4QKN"
      // https://www.dropbox.com/sh/32fxvhu49y1axo1/AAA-UGe-mTuwerKGr42nCbtfa?dl=0
    ],
    category: ["Helix"],
    popularity: 1,
    genre: "Metal",
    price: 5
  },
  {
    id: "jazz-preset-helix",
    meta_description: "Smooth Jazz Line 6 Helix Preset .hlx",
    title: "A Jazz Preset for Helix",
    short_description: "Helix & HX Stomp Fingerstyle Jazzy Tone",
    image: "https://i.imgur.com/bs3GY3u.jpg",
    description: "A smooth, dark jazz tone. Great for fingerstyle.",
    youtube: ["https://www.youtube.com/watch?v=BRijR1Ze514"],
    descriptionMid: "Works best with your <b>neck</b> pickup.",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=SPZC5M2Q7QR4L"
    ],
    category: ["Helix", "hx stomp"],
    popularity: 6,
    genre: "Jazz",
    price: 3.75
  },
  {
    id: "biasfx2-john-petrucci-illumination-theory",
    meta_description: "Bias FX 2 John Petrucci rhythm Illumination Theory",
    title: "Illumination Theory Rhythm Positive Grid Bias FX",
    short_description: "Bias FX 2 Preset",
    image: "https://i.imgur.com/2qRHcTU.jpg",
    description: "My First Bias FX 2 Preset!",
    youtube: ["https://www.youtube.com/watch?v=bpzSEaKfUpE"],
    descriptionMid: "Let's make this preset <b>legendary</b>!",
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/illumination-theory-rhythm-preset-bias-fx-2"
    ],
    descriptionBot:
      "You will download this preset via ToneCloud. Once you complete the payment, you will be redirected to a link that contains the preset's secret ToneCloud Name.",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=C3K2KEE6TFL28"
    ],
    category: ["Bias FX"],
    popularity: 6,
    genre: "Prog",
    price: 4.75
  },
  {
    id: "helix-john-petrucci-rhythm-dark-eternal",
    meta_description:
      "Helix John Petrucci rhythm preset tone dark eternal night",
    title: "John Petrucci Helix Dark Eternal Night",
    short_description: "Helix & Hx-Stomp Presets",
    image: "https://i.imgur.com/GxzESw7.jpg",
    description:
      "Legendary band, legendary album, legendary player, legendary tone.",
    youtube: ["https://www.youtube.com/watch?v=dKJFOl3DCiY"],
    descriptionMid: "Let's make this preset <b>legendary</b>!",
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/dark-eternal-night-helix-preset"
    ],
    descriptionBot: "This package includes <b>2 Impulse Responses</b>.",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=NNYBTQJK4LM5C"
    ],
    category: ["hELiX", "hx stomp"],
    popularity: 6,
    genre: "Prog",
    price: 4.75
  },
  {
    id: "dimebag-helix-pantera-tone",
    meta_description:
      "Dimebag Darrell Helix HX Stomp Pantera Preset free Download Tone",
    title: "Dimebag's Tone Line 6 Helix Pantera",
    short_description: "Dimebag's Tone for the Helix & HX stomp",
    image: "https://i.postimg.cc/63x04gTv/serveimage-min.png",
    description:
      "My attemp to recreate Dimebag's Legendary Tone from the song 'Floods'.",
    descriptionMid: "This package includes a custom IR",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=ZC84UDTKRJMMS"
      // "https://www.dropbox.com/sh/4lvtysy3h15pqff/AACsf9S0ofr6W3o7VZ9bcRgga?dl=0"
    ],
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/dimebag-darrell-pantera-helix-guitar-tone"
    ],
    category: ["helix", "hx stomp"],
    genre: "Metal",
    price: 4.75,
    popularity: 6
  },
  {
    id: "opeth-helix-solo-windowpane",
    meta_description:
      "Opeth Smooth Lead Low gain Helix HX Stomp free Download preset",
    title: "Opeth Helix Tone | Windowpane",
    short_description: "Helix Smooth Lead Preset",
    image: "https://i.postimg.cc/kGwK6jyw/serveimage.jpg",
    description:
      "A cover of the final solo, in the track Windowpane by Opeth. From the legendary album Damnation.<br><br>I made this silky smooth lead patch for the Helix, it's a swiss army knife. Hope you enjoy!",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=P68C9BJNLHZDE"
      // "https://www.dropbox.com/sh/nswc8df7krar8k5/AACKWDodAxb5EZ7oxdexBX9pa?dl=0"
    ],
    youtube: ["https://www.youtube.com/watch?v=XK8VFwpwBrM"],
    descriptionMid: "This package includes a custom IR",
    category: ["helix"],
    genre: "Prog",
    price: 0,
    popularity: 6
  },
  {
    id: "nocturnus-death-metal-helix",
    meta_description: "Death Metal Preset Line 6 Helix HX Stomp Nocturnus",
    title: "Nocturnus | Thresholds",
    short_description: "Line 6 Helix & HX Stomp preset",
    image: "https://i.imgur.com/Pz8FTCv.png",
    description:
      "A cool guy approached me and asked me to build this tone for his Helix. And here it is… ❤ In the following demo I used my Dean guitar loaded with a Dimarzio X2N on the bridge.",
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/nocturnus-thresholds"
    ],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=SLQ6AQXJ9RJLE"
    ],
    category: ["hELiX", "hx stomp"],
    popularity: 4,
    genre: "Metal",
    price: 4
  },
  {
    id: "morbid-angel-helix-lead-tone",
    meta_description: "Morbid Angel Lead Tone Helix HX Stomp Line 6 Download",
    title: "Morbid Angel | Ageless Still I Am",
    short_description: "Line 6 Helix, HX Stomp Epic Lead Preset",
    image: "https://i.imgur.com/jYqQQQ1.jpg",
    description:
      "I did a cover of this truly orgasmic solo within ‘Ageless Still I Am’ by Morbid Angel.",
    youtube: ["https://www.youtube.com/watch?v=Ty0G3HMkbg4"],
    soundcloud: ["https://soundcloud.com/ocarinainmypants/secret-solo"],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=KYCUB4Y9ZEAJYS",
      "http://satoshibox.com/aefgnni32gjaw5ce73w6v67z"
    ],
    buttonDescription: ["buy with PayPal", "buy with crypto"],
    category: ["hELiX", "hx stomp"],
    popularity: 7,
    genre: "Metal",
    price: 3.75
  },
  {
    id: "monuments-helix-bias-stygianblue",
    meta_description: "Monuments Djent Tone Helix Line 6 Bias FX Download",
    title: "Monuments | Stygian Blue",
    short_description: "Line 6 Helix, Bias FX Presets",
    image: "https://jimmypickboy.files.wordpress.com/2018/11/serveimage.jpg",
    description:
      "<h3>The new Monuments album is LIT 🔥</h3><br>I had to build some badass new patches, to capture the upgraded tone of our beloved djentlords, Olly Steele and John Browne. ❤️<br><br>🎸 These presets deliver brutal bottom end, as well as articulate, crispy high end. Use these patches with your bridge pickup, turn the volume and tone pot on 10. In this video, I am using my homemade 7 string that is based on a JEM7V7, loaded with Dimarzio Illuminator Pickups.",
    youtube: ["https://www.youtube.com/watch?v=irV6R9o8ag0"],
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/monuments-stygian-blue-guitar-tone-bias-fx-helix"
    ],
    descriptionBot:
      "<blockquote>Don't Forget to join our <a href='https://www.facebook.com/groups/Line6HelixTopPresets/'>Facebook Community</a> and chat with our satisfied customers</blockquote><hr/><blockquote><h5><a href='https://thisismonuments.bigcartel.com/'>Support Monuments</a> and their latest work</h5></blockquote>",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=38S9BGP4Q5QLS",
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=2LC8EE2TRCVY6",
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=T6G9L6W2L436E"
    ],
    buttonDescription: [
      "Helix Preset",
      "Bias FX preset",
      "Helix + Bias FX preset"
    ],
    category: ["hELiX", "bias fx"],
    popularity: 7,
    genre: "Djent",
    price: 4.2
  },
  {
    id: "steve-vai-helix-china",
    meta_description: "Steve Vai Epic Tone Line 6 Helix Download",
    title: "Steve Vai | Weeping China Doll",
    short_description: "Line 6 Helix Rhythm Tone",
    image: "https://i.imgur.com/TAGhiqd.jpg",
    description:
      "An epic guitar tone I made on the Line 6 Helix 🌀, inspired by Mr. Steve Vai’s masterpiece “Weeping China Doll”, from the album “The Story Of Light”<br><br>🎸 This preset delivers tons of sustain, some epic time related effects and a massive grindy timbre. Use this patch with your bridge pickup, turn the volume and tone pot on 10. In this video, I am using my homemade 7 string that is based on a JEM7V7, loaded with Dimarzio Illuminator Pickups.",
    youtube: ["https://www.youtube.com/watch?v=skyVFBYhr1w"],
    descriptionBot:
      "<blockquote>Don't Forget to join our <a href='https://www.facebook.com/groups/Line6HelixTopPresets/'>Facebook Community</a> and chat with our satisfied customers</blockquote>",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=ZFC7ZEEDL7A66"
    ],
    category: ["hELiX"],
    popularity: 5,
    genre: "Prog",
    price: 4.75
  },
  {
    id: "korn-line-6-helix-rhythm-tone",
    meta_description: "Korn Line 6 Helix Metal Heavy Preset Download",
    title: "Korn | Here to Stay",
    short_description: "Line 6 Helix Rhythm Tone",
    image: "https://i.imgur.com/hSS2HlT.jpg",
    description:
      "Here is my cover of the song “Here to Stay” using this preset…",
    youtube: ["https://www.youtube.com/watch?v=5Euwp8cTheY"],
    descriptionMid:
      "~ Gear that I used:<br><br><ul><li>Custom 7 String with Illuminator pickups</li><li>D'Addario Strings</li><li>Scarlett 2i2</li><li>Line 6 Helix</li><li>A well known free IR, that is included in the package that you get.</li><li style='list-style-type:none;'></li></ul>",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=JYD8TKBTMVCS8"
    ],
    category: ["hELiX"],
    popularity: 5,
    genre: "Metal",
    price: 3.95
  },
  {
    id: "btbam-helix-lead-tone",
    meta_description: "BTBAM Line 6 Helix Preset Lead Download",
    title: "BTBAM | Millions - Epic Lead Tone",
    short_description: "Line 6 Helix & HX Stomp Lead Tone",
    image: "https://i.imgur.com/26UZ3wR.jpg",
    description:
      "I made a tone inspired by the latest album of Between the Buried and Me, AUTOMATA I.<br><br>My favorite moment from their latest album, is this lead section from the track “Millions”.",
    youtube: ["https://www.youtube.com/watch?v=Jn0ybLrUkUE"],
    descriptionMid:
      "This preset delivers infinite sustain, juicy time based effects, and a really smooth sonic character.",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=4F93WSXCHVFYL"
    ],
    category: ["hELiX", "hx stomp"],
    popularity: 5,
    genre: "Prog",
    price: 4.75
  },
  {
    id: "friedman-be-100-bias-amp",
    meta_description:
      "Friedman Be 100 for Bias Amp Amp match Port Positive grid",
    title: "Friedman BE-100",
    short_description: "for Bias AMP",
    image: "https://i.imgur.com/7QNtg7m.png",
    description:
      "This is my dream amp. I’ve tried really hard to find something that sounds close to the actual thing on Positive Grid’s Tonecloud and I failed. Again and again.<br>So I decided to build one by myself and here we are…<br><br>This model pushes the limits of what Bias Amp is capable of. Insanely tuby, responsive and gnarly. Deliciously crunchy, with applications across all sorts of genres and styles. You can clearly hear every detail of your picking hand’s movements. My new favorite Bias Amp Model without a second thought!<br><br>In the following demo I play some stoner style rhythm, modern progressive metal, some crunchy lead and some pushed cleans. Enjoy!",
    descriptionMid:
      "The first part is the amp inside a full fx-chain...<br> The last part (“All new materials – Periphery”) is the amp by itself… <br>I am using my homemade 7-string loaded with Dimarzio Illuminator pickups.",
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/friedman-be-100-bias-amp-model"
    ],
    descriptionBot:
      "The package you get includes the Bias Importer Tool.<br><br>Don't forget to visit <a href='/faq'>my F.A.Q.</a> page for instructions.<br><br>Join <a href='https://www.facebook.com/groups/biasbestpresets/'>our Facebook group</a> and share your thoughts about this product:<br><br><img src='https://jimmypickboy.files.wordpress.com/2018/03/ezgif-com-crop1.gif' />",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=WP2WAXUZL6KM2",
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=JNNDD5NUUG6NE"
    ],
    buttonDescription: ["Bias Amp Model", "+ Bias FX Lead, Rhythm Presets"],
    category: ["Bias Amp", "Bias FX"],
    popularity: 6,
    genre: "Rock",
    price: 6
  },
  {
    id: "whitechapel-preset-line6-helix",
    meta_description:
      "Djent Whitechapel Rhythm Heavy Meatl Line 6 Helix Preset Free Download",
    title: "Whitechapel Rhythm Tone",
    short_description: "Heavy Line 6 Helix Preset",
    image: "https://i.imgur.com/1UlvcjS.png",
    description:
      "The Greatest Rhythm tone I own for the Helix at the moment. If you are into chuggs, you won’t be able to live without this patch after giving it a shot. Tight bottom and Crisp Top. Tons of Mojo. Excellent for satisfying palm muting!<br><br>It is a really balanced tone, making it fun to jam and inspiring to write riffs with, but also ready to fit in your modern metal mix.<br><br>Please note that the instrument I am using has a standard scale length of 25.5 inches. If you own a baritone you will get a more tight and menacing final tone. Cheers!",
    youtube: [
      "https://www.youtube.com/watch?v=72l-aOrnUSA",
      "https://www.youtube.com/watch?v=YeloxnczeUM"
    ],
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/insane-rhythm-tone-for-line-6-helix-in-the-style-of-whitechapel"
    ],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=M6K6A979ZLSZW"
    ],
    category: ["hELiX"],
    popularity: 5,
    genre: "Djent",
    price: 4.5
  },
  {
    id: "tame-impala-bass-slap-line-6-helix",
    meta_description:
      "Tame Imapla Bass Slap Punchy Preset Patch Tone Line 6 Helix HX Stomp Download",
    title: "Tame Impala - The Less I know the better",
    short_description: "Bass Slap Tone for Line 6 Helix",
    image: "https://i.imgur.com/WZXn6E7.jpg",
    description:
      "Here I am covering the intro from Tame Impala’s “The Less I Know The Better” using this mean helix patch:",
    youtube: ["https://www.youtube.com/watch?v=oPnCHQvEJAw"],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=DFVEJ43C9SPDC"
    ],
    descriptionBot:
      "~2 External Impulse Responses are included in this package<br><br>~I am using Both P and J pickups (P-volume:50% J-Volume:100%)",
    category: ["helix", "bass"],
    popularity: 3,
    genre: "Rock",
    price: 3.5
  },
  {
    id: "intervals-lead-tone-line-6-helix",
    meta_description: "Intervals Lead Preset Patch Tone Line 6 Helix HX Stomp",
    title: "Intervals Lead Tone",
    short_description: "Modern Lead Tone for Line 6 Helix and HX Stomp",
    image: "https://i.imgur.com/dxJ03GW.jpg",
    description:
      "An Amazing Lead tone for Line 6 Helix users, in the style of Aaron Marshall!<br><br>Tons of Sustain. Crispy bottom and top. Immaculate articulation. My new “go-to” lead tone! Despite the fact that this patch was released recently it has already become one of the site’s best sellers…<br><br>I am using my bridge pickup (Illuminator 7), volume pot is on 10.",
    youtube: ["https://www.youtube.com/watch?v=0HBuHtO1sAk"],
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/modern-lead-tone-in-the-style-of-aaron-marshall-line-6-helix"
    ],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=PKR4JABG4QDFN"
    ],
    descriptionBot:
      "SUPPORT <a href='http://www.intervalsmusic.net/'>INTERVALS</a> and their latest work! 💓",
    category: ["helix", "hx stomp"],
    popularity: 8,
    genre: "Djent",
    price: 4.75
  },
  {
    id: "wintersun-biasfx",
    meta_description: "Wintersun Sons of winter and stars bias fx preset",
    title: "Wintersun | Sons of Winters and Stars",
    short_description: "Customer's request for Bias FX",
    image: "https://i.imgur.com/XML5e3s.png",
    description:
      "A wonderful addition to your Metal Rhythm Tone Preset collection for Bias FX!<br><br>This package Contains: A Custom Bias Amp model, Custom Bias Pedal and The main Bias FX patch. Enjoy!",
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/wintersun-sons-of-winter-and-stars-guitar-tone-bias-fx"
    ],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=AGXYRPL694UME"
    ],
    category: ["Bias fX"],
    popularity: 1,
    genre: "Prog",
    price: 3.5
  },
  {
    id: "pariah-steven-wilson-lead-tone",
    meta_description:
      "Steven Wilson Guthrie Govan Lead Tone Line 6 Helix Preset",
    title: "Pariah - Steven Wilson - Lead Tone",
    short_description: "Play your guitar like a violin",
    image: "https://i.imgur.com/wZXzPCu.jpg",
    description:
      "This Ambient / Drone Line 6 Helix Preset, allows you to play your guitar like a violin using any metallic object around you. In this demo I am using a Pen! Enjoy!",
    youtube: ["https://www.youtube.com/watch?v=VztH5rpwdio"],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=6J3MSLXMDBLXE"
    ],
    category: ["Helix"],
    popularity: 6,
    genre: "Prog",
    price: 3.95
  },
  {
    id: "guitar-rig-5-top-3-presets",
    meta_description: "Guitar Rig 5 Top 3 Presets Free Download Modern",
    title: "Guitar Rig 5 - My top 3 presets",
    short_description: "A blast from the past",
    image: "https://i.imgur.com/RpWsF9V.jpg",
    description:
      "I was revisiting good old Guitar Rig 5 the other day, and realized that some fine tuned patches I built a long of time ago still keep up with today’s standards. Let’s have a listen….",
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/guitar-rig-5-in-2017-my-top-3-presets-old-but-gold"
    ],
    descriptionBot: "This package includes 3 .ngrr files",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=XFGPEMUDDTL4E"
    ],
    category: ["guitar rig 5"],
    popularity: 3,
    genre: "Metal",
    price: 3.25
  },
  {
    id: "mastodon-high-road-guitar-free-vst",
    meta_description:
      "Mastodon Rhythm Guitar Tone High Road Free Vst Plugins Preset Download",
    title: "Mastodon High Road Rhythm Guitar Tone ",
    short_description: "With Free Vst Plugins",
    image: "https://i.imgur.com/FjZAU9X.png",
    description:
      "I am using my Homemade 7 string loaded with Dimarzio Illuminators. Tuning Drop A. Bridge Position. Pot Volume on 10.",
    descriptionMid:
      "Yep my riffage needs some extra work. The low end is a bit out of control, this effect could be reduced but it feels really good to play with that type of sound.",
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/mastodon-high-road-guitar-rhythm-tone-request-made-with-free-vst-plugins"
    ],
    descriptionBot:
      "-Made using free VST plugins and IRs exclusively<br>-This package includes pictures of the plugin's settings, '.FXP' files for each plugin in the chain and The Impulse Response that I used.<br>You can check out <a href='/?free=true'>my free stuff</a> before purchasing...",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=E78YYN3MUAPDU"
    ],
    category: ["free vst"],
    popularity: 4,
    genre: "Prog",
    price: 3
  },
  {
    id: "single-coil-mojo-bias-fx",
    meta_description:
      "Single Coil Start Tone Positive Grid Preset Bias FX Download",
    title: "Single Coil Mojo Tone",
    short_description: "Bias FX Lead Tone",
    image: "https://i.imgur.com/3jxrhsk.jpg",
    description:
      "I decided to dust off my good old fender and release this amazing patch that I’ ve been working on for quite some time. This patch is a swiss army knife. Simply by tweaking the pickup selector switch, you can have a Tool , Stoner Rock rhythm type of tone, or a Nick Johnston , Wes Hauch, Jack Thammarat, Rick Graham type of spanky lead tone.",
    descriptionMid:
      "First Riff: Bridge Coil <br> Second Riff: Middle and Bridge Coil <br> Third Riff: Neck and Middle Coil <br> Fourth Riff: Neck Coil.<br><br>I am using a Fender Squier Bullet Strat loaded with Dimazrio Injectors (Neck , Bridge) and an Area 61(Middle)(Volume and Tone are on 10) (I am only switching pickups).",
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/bias-fx-single-coil-mojo-preset"
    ],
    descriptionBot:
      "-This package includes one BIAS FX Patch.<br>-What you hear in these Demos is one centered guitar track. BIAS FX ONLY!<br>-My patches follow the K-14 standard.<br>-Visit the <a href='/faq'>F.A.Q.</a> section to learn how to import the patch to Bias FX.<br>-<a href='/contact'>Contact Me</a> to answer all your questions.<br>(I can reamp one of your DIs through this patch, in case you are hesitating).",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=9AV4X52TBN4HC"
    ],
    category: ["bias fx"],
    popularity: 5,
    genre: "Prog",
    price: 3.5
  },
  {
    id: "thall-biasfx",
    meta_description:
      "Thall Vildhjarta Bias FX Positive Grid Preset Free Download",
    title: "THE THALL preset for Bias FX",
    short_description: "Ballsy rhythm tone for bias fx",
    image: "https://i.imgur.com/HxET2VU.jpg",
    description:
      "This one is made using my <a href='/preset/dar-forza-quatro-bias-amp'>DAR Forza Model</a>.",
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/thall-bias-fx-patch-ballsy-rhythm-presets"
    ],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=NBM6STFLHJL3U"
    ],
    category: ["bias fx"],
    popularity: 5,
    genre: "Thall",
    price: 3.5
  },
  {
    id: "chugg-biasfx",
    meta_description: "Chugg Rhythm Bias FX Positive Grid Preset Free Download",
    title: "THE Chugg preset for Bias FX",
    short_description: "Ballsy rhythm tone for bias fx",
    image: "https://i.imgur.com/RPLqpFb.jpg",
    description:
      "This one is made using my <a href='/preset/fortin-meathead-ch6_bias-amp'>FORTIN Meathead Model</a> and one of my <a href='/preset/jake-bowen_summer-jam'>free Amp Models</a>. Fortin focuses on the lows and mids, while JB SUMMER JAM on the highs.",
    soundcloud: ["https://soundcloud.com/ocarinainmypants/chugg2"],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=XCZ24QYMVAD5W"
    ],
    category: ["bias fx"],
    popularity: 3,
    genre: "Metal",
    price: 3
  },
  {
    id: "djent-biasfx",
    meta_description: "Djent Positive Grid Bias FX Preset Download",
    title: "THE Djent preset for Bias FX",
    short_description: "Ballsy rhythm tone for bias fx",
    image: "https://i.imgur.com/XKrvWVQ.jpg",
    description:
      "This one will give that Monuments style tone. It is actually a heavily modified version of the tone Olly Steele released. With much better Amp Models and a sweet OD that pushes and tightens everything up.",
    youtube: ["https://www.youtube.com/watch?v=8pPQHtHlM5c"],
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/ballsy-riff-idea-djent-bias-fx-patch",
      "https://soundcloud.com/ocarinainmypants/djent-bias-fx-patch-ballsy-rhythm-presets"
    ],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=CSTC8PHPCWCVG"
    ],
    category: ["bias fx"],
    popularity: 5,
    genre: "Djent",
    price: 4
  },
  {
    id: "fortin-meathead-ch6-bias-amp",
    meta_description: "Fortin MeatHead Channel 6 Bias Amp Port Amp Match Model",
    title: "Fortin Meathead for Bias Amp ",
    short_description: "Bias Amp Model",
    image: "https://i.imgur.com/YqdwUv4.png",
    description:
      "I amp matched a FORTIN Meathead (channel 6)<br><br>The best word to describe this model is IMMENSE. Based on the channel 6 of a Fortin Meathead. Here is a small Demo with some happy riffage on my homemade 7 string.",
    descriptionMid:
      "In this demo I am using a Bias FX Patch that is using this AMP Model.<br><br>What you hear is one centered guitar track.<br><br> I try to avoid simply Amp-Matching an amp and then releasing it. I add many twists to make my models special, in a way.",
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/fortin-meathead-ch-6-bias-amp-model"
    ],
    descriptionBot:
      "This package includes a Bias Amp model.<br>You will need the Professional License Tier for this one.<br><br><img src='https://jimmypickboy.files.wordpress.com/2016/07/cebaceb1cf84ceb1ceb3cf81ceb1cf86ceae.png'/>",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=2NT4PD67YXPRS"
    ],
    category: ["bias amp"],
    popularity: 4,
    genre: "Metal",
    price: 3
  },
  {
    id: "axe-fx-dizzy-v4-silver3-bias-amp",
    meta_description: "AxeFx II Dizzy Silver 3 Bias Amp Port Amp Match Model",
    title: "AxeFx II Dizzy Silver 3 for Bias Amp ",
    short_description: "Bias Amp Model",
    image: "https://i.imgur.com/nuE952L.png",
    description:
      "This Bias Amp Model is based on a popular Axe FX II model in the Metal and Prog community (Dizzy V4 Silver 3). The way your right hand hits the strings has an impact on the timbre. This hybrid amp model is pretty much a Swiss Army knife. Check out the range of tones you can get simply by turning the gain knob in the following demo…",
    descriptionMid:
      "My goal with this one was not to make it sound just like a real Diezel, but to create a tonally rich little Bias Amp hybrid model using the initial AXE FX II Dizzy model as a good starting point. I put the AXE FX II GUI on top of it just for fun.<br><br>This Bias Amp Model is Amp-Matched to an AX8 with Quantum firmware 3.04. I am running Bias AMP DSP Engine 858. The first two riffs is Bias AMP with a tube screamer (Bias Pedal) loaded in the front (Gain at 12 o’clock, bridge pickup). The last part is just Bias AMP, nothing else (Gain at 9 o’clock, neck pickup). No Post Processing! In this demo I used my homemade 7 string loaded with Dimarzio Illuminators.",
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/fractal-audios-dizzy-v4-silver-3-inside-bias-amp"
    ],
    descriptionBot:
      "For this model you won't need the Professional license, nor the IR loader.",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=WJ9J6A5MRMA6A"
    ],
    category: ["bias amp"],
    popularity: 4,
    genre: "Prog",
    price: 3
  },
  {
    id: "axe-fx-ii-fas-modern-iii-bias-amp",
    meta_description: "AxeFx II Fas Modern Bias Amp Port Amp Match Model",
    title: "AxeFx II Fas Modern III for Bias Amp ",
    short_description: "Bias Amp Model",
    image: "https://i.imgur.com/4EjP7Yb.png",
    description:
      "I have been working on this one for quite some time. This Bias Amp model is based on an overused AXE FX II amp model in the Djent/Prog community. I added many twists here and there to add extra spice and juice.<br>The timbre of this hybrid amp reacts to the amount of pick attack you are using. An excellent choice for rhythm purposes and Djenty stuff. Palm muting and chugging never felt so good! Your Amp collection cannot miss this one!",
    descriptionMid:
      "This Bias Amp Model is Amp-Matched to an AX8 with Quantum firmware 3.04. I am running Bias AMP DSP Engine 858. The first part is FAS Modern III (Bias Amp Model) with a tube screamer loaded in the front and a bit of reverb post. The last part is just the amp. No Post Processing! In this demo I used my homemade 7 string loaded with Dimarzio Illuminators.",
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/fas-modern-iii-inside-bias-amp"
    ],
    descriptionBot:
      "You will need the Professional License Tier for this one.<br><br><img src='https://jimmypickboy.files.wordpress.com/2016/07/cebaceb1cf84ceb1ceb3cf81ceb1cf86ceae.png'/>",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=UK5NZRL6VAEDJ"
    ],
    category: ["bias amp"],
    popularity: 4,
    genre: "Djent",
    price: 4
  },
  {
    id: "bias-fx-pickboy-smooth-lead",
    meta_description:
      "Smooth Lead Bias FX low gain Positive Grid Preset Free Download",
    title: "Pickboy Smoooth Lead",
    short_description: "Bias FX Free Preset",
    image: "https://i.imgur.com/BDMXf0S.jpg",
    description:
      "A sweet low gain lead preset I made the other day. You can get it from ToneCloud. Search for <b>‘Pickboy Smoooth Lead’</b>. If you liked this BIAS FX Preset you will be amazed from my other patches. ",
    youtube: ["https://www.youtube.com/watch?v=tGhPHXH-gQk"],
    descriptionMid:
      "I am using my homemade 7 string ‘Icarus VII’ to demo this tone. Neck pickup:Illuminator 7<br><br>If it doesn’t sound right turn your sampling rate to 96k<br><br>Enjoy!",
    category: ["bias fx", "free"],
    popularity: 3,
    genre: "Prog",
    price: 0
  },
  {
    id: "bias-fx-5150III-lead-tone",
    meta_description: "Shred Lead Bias FX Positive Grid Preset Free Download",
    title: "5150 III Shred Lead Tone",
    short_description: "Bias FX Free Preset",
    image: "https://i.imgur.com/Tr8q6az.jpg",
    description:
      "Search on the Tonecloud for: <b>“Pickboy SHRED”</b>. Test it and tell me what you think!<br><br>My “go to” lead preset. Great for speed, pick articulation in both neck and bridge position. Sounds great for rhythm too! If you enjoyed this one you really need to check out my other releases.<br><br>Make sure you use 96k sampling rate, otherwise it doesn’t sound right.",
    youtube: [
      "https://www.youtube.com/watch?v=NHB13w0KCFU",
      "https://www.youtube.com/watch?v=TU_bwsMehms"
    ],
    descriptionMid:
      "I am using a Scarlett 2i2 Focusrite audio interface and my homemade 7 string.<br>No fretwraps or anything similar is used.<br>Pickup used: Dimarzio Illuminator neck pickup, pot volume is full.<br>Check out my other<a href='/?free=true'> free stuff</a>.",
    category: ["bias fx", "free"],
    popularity: 4,
    genre: "Metal",
    price: 0
  },
  {
    id: "ambient-free-vst-guitar-rig-5",
    meta_description:
      "Clean Ambient Tone Modern Djent Preset Free Vst plugins Guitar Rig 5 Free Download",
    title: "Ambient – IcarusVII Test Drive",
    short_description: "Free Vst Plugins + Guitar Rig 5",
    image: "https://i.imgur.com/BDMXf0S.jpg",
    description:
      "The main timbre of this plugin is crafted on Ignite Amps plugins.<br> For the spacey stuff I used good old Guitar Rig 5.<br> I am using both Dimarzio Illuminator 7 bridge & neck pickups in parallel mode. Volume on 10. If you have single coils they will work even better!",
    youtube: [
      "https://www.youtube.com/watch?v=qAer_ljmy1s",
      "https://www.youtube.com/watch?v=xmfxx_oPeXs"
    ],
    descriptionMid:
      "Made using free VST plugins and Guitar Rig 5<br>This package includes pictures of the plugin’s settings, “.FXP” files for each plugin in the chain, The Impulse Responses that I used and the “.ngrr” file for Guitar Rig 5.",

    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/ambient-tone-homemade-7-string-test-drive"
    ],
    descriptionBot:
      "Discount Package (Customer's Request)...<br> You can also get both 'IcarusVII Test Drive' and <a href='https://jimmypickboy.wordpress.com/2015/09/25/in-hearts-wake-healer-ambient-patch/'>'In Hearts Wake - Healer'</a> Ambient Presets With a Discount!!!",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=9LTUR36JHQEUQ",
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=U66PX7NLDH4EJ"
    ],
    buttonDescription: ["Get This Tone", "+ In Hearts Wake - Healer, discount"],
    category: ["guitar rig 5", "free vSt"],
    popularity: 4,
    genre: "Clean",
    price: 3
  },
  {
    id: "victory-vx-the-kraken-bias-amp",
    meta_description: "Victory VX The Kraken Positive Grid Amp Model Download",
    title: "Victory VX The Kraken",
    short_description: "Bias Amp Model",
    image: "https://i.imgur.com/8xuXhRT.png",
    description:
      "I amp matched the sound of the Gain 2 channel of a “Victory VX The Kraken” amp!<br><br>This package includes a Bias Amp model and the IR that is used.<br><br>One of my favorite models so far and one of the site’s best sellers! Insanely bright and crisp! Great for cleans and low gain leads!",
    youtube: ["https://www.youtube.com/watch?v=qAer_ljmy1s"],
    descriptionMid:
      "First Part: Neck Pickup (Dimarzio liquifire) 3/4 guitar volume knob . Bias Amp ONLY! <br><br>Second Part: Bridge Pickup (Dimarzio X2N) guitar volume full . Bias Amp + good old <a href='http://www.igniteamps.com/en/audio-plug-ins'>Ingite Amps TSB-1</a> in front of it.",
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/victory-vx-the-kraken-bias-amp-matched-demo"
    ],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=5NXPHAGR73XMY"
    ],
    category: ["Bias Amp"],
    popularity: 6,
    genre: "Metal",
    price: 4.2
  },
  {
    id: "dar-forza-quatro-bias-amp",
    meta_description: "Metal Dar Forza Quatro Positive Grid Amp Model Download",
    title: "DAR Forza Quattro",
    short_description: "Bias Amp Model",
    image: "https://i.imgur.com/q7iUrey.png",
    description:
      "This package includes a Bias Amp Model Amp Matched to a DAR Forza Quattro! This model has a custom user interface. <br><br>Listen to these samples:<br>(Only one guitar track)<br><br>Oh boi! I wish I had an 8-string to demo this. I actually used a fender start in this demo",
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/dar-forza-bias-amp-matched-demo"
    ],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=DDR368ALF5CRY"
    ],
    category: ["Bias Amp"],
    popularity: 2,
    genre: "Metal",
    price: 2.5
  },
  {
    id: "driftwood-purple-nightmare-bias-amp",
    meta_description:
      "Metal Driftwood Purple Nightmare Positive Grid Amp Model Download",
    title: "Driftwood Purple Nightmare",
    short_description: "Bias Amp Model",
    image: "https://i.imgur.com/MnMNpTc.png",
    description:
      "This package includes a Bias Amp Model Amp Matched to a Driftwood Purple Nightmare!<br>This model features a custom user interface. A really cool amp for brutal riffage. <br><br>Listen to these samples:<br>(Only one guitar track)",
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/driftwood-purple-nightmare-bias-amp-matched-demo"
    ],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=8GPY4PFDZY8U6"
    ],
    category: ["Bias Amp"],
    popularity: 4,
    genre: "Metal",
    price: 3
  },
  {
    id: "the-faceless-bias-amp-lead-solo",
    meta_description:
      "Metal Positive Grid Bias Amp Faceless Kenee Preset Download",
    title: "Eidolon Reality Solo",
    short_description: "Lead Tone",
    image: "https://i.imgur.com/T9aNsZU.jpg",
    description:
      "Made using Bias Pedal and Bias Amp <br>(If you don’t have Bias Pedal I’ve included an alternative)<br>I am using a Dimarzio X2N in the bridge position. Volume and Tone are on 10.",
    youtube: ["https://www.youtube.com/watch?v=VZnCr2unnCc"],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=C4FV9AGQUZ4NQ"
    ],
    category: ["bias amp", "bias pedal"],
    popularity: 3,
    genre: "Metal",
    price: 3
  },
  {
    id: "solocontest2016-entry-tone",
    meta_description: "Jimmy Pickboy Solo Contest Entry Tone 2016",
    title: "#Solocontest2016 entry tone",
    image: "https://i.imgur.com/mf1XupL.png",
    description:
      "Many people wanted this one, so here it is. I crafted this patch so that it’s good for both crunchy and percussive stuff. I am using a Dimarzio liquifire in the neck position. Volume and Tone are on 10. Throughout my solo I use this exact tone.",
    descriptionMid:
      "This package includes a Bias Amp Model, .FXP files for all the free plugins used in the chain, pics to help you out with installation",
    youtube: ["https://www.youtube.com/watch?v=XLQj082Z-1I"],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=TSPSYNZZCDE9A"
    ],
    category: ["Bias Amp", "free vSt"],
    popularity: 0,
    genre: "Prog",
    price: 2.9
  },
  {
    id: "leprous-the-flood-bass",
    meta_description:
      "Progressive Bass Slap Tone Free Vst Plugins Leprous Slap",
    title: "Leprous – The Flood",
    short_description: "Bass Slap Tone",
    image:
      "https://jimmypickboy.files.wordpress.com/2015/10/leprous-thecongregation.jpg",
    description:
      "I added some bass slap to the song! I learned to play the bass guitar a couple of weeks ago, so my playing kinda sucks. This song is super proggy my timing is a bit off…",
    descriptionMid:
      "This package includes .FXP files for all the plugins in the chain, the Impulse Responses used , pics and documentation to help you out.",

    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/leprous-the-flood-bass-slap"
    ],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=5RB6MT257JY82"
    ],
    category: ["bass", "free vSt"],
    popularity: 1,
    genre: "Prog",
    price: 2.5
  },
  {
    id: "jake-bowen-summer-jam",
    meta_description:
      "Djent Positive Grid Bias Amp Periphery Jake Bowen Preset Summer Jam",
    title: "Jake Bowen’s Summer Jam Tone ",
    short_description: "from the DiMarzio Titan video",
    image:
      "https://jimmypickboy.files.wordpress.com/2015/11/28589__1_1024x1024-e1511636351991.jpg",
    description:
      "Search the cloud for <b>'JB Summer Jam'</b> <br><br> I am using the <a href='https://www.tseaudio.com/software/tse808'>TSE 808</a> screamer pedal in front of the amp and <a href='http://www.vst4free.com/free_vst.php?id=683'>TAL Reverb II</a> post, to fatten things up a little. Everything else is taken care of within Bias AMP. The guitar I am using in this demo is a cheap Fender Squier Bullet Strat, loaded with a DiMarzio Injector single coil pickup in the bridge position.",
    descriptionMid:
      'Here\'s the settings... <br><img src="https://jimmypickboy.files.wordpress.com/2015/11/tse.png?w=167" alt="tse 808" width="167" height="300" /></a><img src="https://jimmypickboy.files.wordpress.com/2015/11/jb-summer-jam.png?w=300" alt="JB summer jam" width="300" height="199" /></a><img src="https://jimmypickboy.files.wordpress.com/2015/11/tal.png?w=300" alt="Tal II Reverb" width="500" height="248" /></a>',
    category: ["Bias Amp", "free"],
    popularity: 4,
    genre: "Djent",
    price: 0
  },
  {
    id: "periphery-mile-zero-vst",
    title: "Periphery – Mile Zero",
    meta_description: "Djent Free Vst Periphery Preset Modern Lead",
    short_description: "Lead Tone",
    description:
      "I am using a Dimarzio X2N in the bridge position.I made this sick tone using free VST plugins only. First listen to the original guitar tone, then listen to this one. At the end of the solo I played some riffs, they sound nice and tight right? My hands were really cold during the recording session but you get the idea…. <br> BTW here is an old cover I made using another patch.",
    descriptionMid:
      "This package contains an Impulse Response, .FXP files for all the free plugins used in the chain, pics and documentation to help you out.",
    // descriptionBot: "description <b>Bottom</b>",
    category: ["free vSt"],
    image: "https://jimmypickboy.files.wordpress.com/2015/10/per.png",
    popularity: 3,
    genre: "Djent",
    price: 3,
    youtube: ["https://www.youtube.com/watch?v=4z5KP6uebSI"],
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/periphery-mile-zero-guitar-solo"
    ],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=3YBXPLTC99KM6"
    ]
  },
  {
    id: "the-faceless-bias-amp-rhythm-metal",
    title: "The Faceless – Ten Billion Years",
    meta_description:
      "Metal Positive Grid Preset Rhythm Faceless Kenee Download",
    short_description: "Brutal Guitar Tone",
    description:
      "Believe it or not I am using my little Fender Squier Bullet Strat in this sample!(loaded with a dimarzio injector in the bridge position) This one sounds IMMENSE. My intention was not to make this one as close to the original as possible but to enhance the original tone. If you play this one over the song you will get an explosion. This patch is an excellent choice for cover purposes.",
    descriptionMid:
      "This package includes .FXP files for all the free plugins used in the chain, pics and documentation to help you out.",
    category: ["bias amp", "free vSt"],
    image: "https://i.imgur.com/T9aNsZU.jpg",
    popularity: 2,
    genre: "Metal",
    price: 3,
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/the-faceless-ten-billiob-years-guitar-tone"
    ],
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=5JRL57SYP6FMN"
    ]
  },
  {
    id: "in-hearts-wake-ambient",
    title: "In Hearts Wake – Healer",
    meta_description:
      "Bias Amp Clean Ambient Tone Positive Grid Preset Free Download",
    short_description: "Ambient Tone for Bias Amp",
    description:
      "This package includes a Bias Amp model, all the FXP files for all the free plugins in the chain, pics and documentation to help you. <br> This is the intro ambient tone from the song “healer”. <br> Here is how it sounds like:",
    image: "https://i.imgur.com/71PdX0V.jpg",
    popularity: 1,
    category: ["Bias AMp", "free vst"],
    price: 3.5,
    genre: "Clean",
    paypal: [
      "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=6XSZ3V9F59RS4"
    ],
    soundcloud: [
      "https://soundcloud.com/ocarinainmypants/in-hearts-wake-healer-ambient-intro"
    ]
  }
];

const toggles = [
  // FIXME: I had to put the same values in _vars.scss , _cards.scss
  {
    color: {
      checked: "#0095d7",
      unchecked: "#4fc9ff"
    },
    label: {
      checked: "Helix",
      unchecked: "Helix"
    },
    width: 87,
    value: false
  },
  {
    color: {
      checked: "#1fdfca",
      unchecked: "#69eadc"
    },
    label: {
      checked: "HX Stomp",
      unchecked: "HX Stomp"
    },
    width: 120,
    value: false
  },
  {
    color: {
      checked: "#fb4e52",
      unchecked: "#fc9093"
    },
    label: {
      checked: "Bias Amp",
      unchecked: "Bias Amp"
    },
    width: 115,
    value: false
  },
  {
    color: {
      checked: "#95395d",
      unchecked: "#c76d90"
    },
    label: {
      checked: "Bias Pedal",
      unchecked: "Bias Pedal"
    },
    width: 122,
    value: false
  },
  {
    color: {
      checked: "#d6a224",
      unchecked: "#e6c169"
    },
    label: {
      checked: "Bias FX",
      unchecked: "Bias FX"
    },
    width: 100,
    value: false
  },
  {
    color: {
      checked: "#e58a09",
      unchecked: "#f8b04b"
    },
    label: {
      checked: "Guitar Rig 5",
      unchecked: "Guitar Rig 5"
    },
    width: 132,
    value: false
  },
  {
    color: {
      checked: "#b813b3",
      unchecked: "#f073ed"
    },
    label: {
      checked: "Free Vst",
      unchecked: "Free Vst"
    },
    width: 107,
    value: false
  },
  {
    color: {
      checked: "#8d10e5",
      unchecked: "#b257f3"
    },
    label: {
      checked: "Bass",
      unchecked: "Bass"
    },
    width: 82,
    value: false
  },
  {
    color: {
      checked: "#34e617",
      unchecked: "#69ee53"
    },
    label: {
      checked: "Free",
      unchecked: "Free"
    },
    width: 82,
    value: false
  }
];

const genres = [
  {
    name: "Thall"
  },
  {
    name: "Djent"
  },
  {
    name: "Rock"
  },
  {
    name: "Metal"
  },
  {
    name: "Clean"
  },
  {
    name: "Prog"
  },
  {
    name: "All Genres"
  }
];

// export default data;
export { data, toggles, genres };
