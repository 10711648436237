<template>
  <div class="btn-group">
    <li @click="toggleMenu()" class="dropdown-toggle" v-if="selectedOption.name !== undefined">
      {{ selectedOption.name }}
      <span class="caret"></span>
    </li>

    <li @click="toggleMenu()" class="dropdown-toggle" v-if="selectedOption.name === undefined">
      {{placeholderText}}
      <span class="caret"></span>
    </li>

    <ul class="dropdown-menu" v-if="showMenu">
      <li v-for="(option,o) in options" :key=o>
        <a href="javascript:void(0)" @click="updateOption(option)">
          {{ option.name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { setTimeout } from "timers";
export default {
  data() {
    return {
      selectedOption: {
        name: ""
      },
      showMenu: false,
      placeholderText: "Please select an item"
    };
  },
  props: {
    options: {
      type: [Array, Object]
    },
    selected: {},
    placeholder: [String]
  },

  mounted() {
    this.selectedOption = this.selected;
    if (this.placeholder) {
      this.placeholderText = this.placeholder;
    }
  },

  methods: {
    updateOption(option) {
      this.selectedOption = option;
      this.showMenu = false;
      this.$emit("updateOption", this.selectedOption);
    },
    closeMenu() {
      this.showMenu = false;
    },
    toggleMenu() {
      // Original Code
      // this.showMenu = !this.showMenu;

      // freaky shit
      // https://stackoverflow.com/questions/38399050/vue-equivalent-of-settimeout
      var self = this;
      setTimeout(function() {
        self.showMenu = !self.showMenu;
      }, 20);
    }
  }
};
</script>
