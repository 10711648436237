<template>
<transition name="cards-trans">
    <router-link :to="getLink()"> <!-- this div is needed for the transition to work otherwise the card-wrap loads when the transition ends-->
    <!-- @mousemove="handleMouseMove" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" @click="click($event)" @mousedown="handleMouseDown"-->
    <div class="card-wrap" ref="card">
            <!-- :style="cardStyle" -->
            <div class="card">
                <!-- :style="[cardBgTransform]" -->
                <div class="card-bg" v-lazy:background-image="dataImage" v-once></div>
                <div class="card-category">
                    <button class="button" :class="category.toLowerCase().trim().split(' ').join('')" type='button'>{{category.toLowerCase() | capitalize}}</button>
                </div>
                <div v-if="category2" class="card-category2">
                    <button class="button" :class="category2.toLowerCase().trim().split(' ').join('')" type='button'>{{category2.toLowerCase() | capitalize}}</button>
                </div>
                <div class="card-info">
                    <slot name="header"></slot>
                    <slot name="content"></slot>
                </div>
            </div>

    </div>
    </router-link>
</transition>
</template>

<script>
export default {
  name: "card",
  mounted() {
    // FIXME: there are 3 cards loaded for a while in the mobile version while the transition lasts.
    // FIXME: when multiple 3d effects occur at the same time (only on desktop) fps goes down
    // Removing the transition tag doesn't solve the problem.
    // Removing v-vpshow directive doesn't solve the problem.
  },
  props: {
    id: String,
    dataImage: String,
    category: String,
    category2: String
  },
  data: () => ({
    // mouseDownCoor: 0,
    // width: 0,
    // height: 0,
    // mouseX: 0,
    // mouseY: 0,
    // mouseLeaveDelay: null,
  }),
  //computed: { // Computed properties slow things down?
  // mousePX() {
  //   return this.mouseX / this.width;
  // },
  // mousePY() {
  //   return this.mouseY / this.height;
  // },
  // cardStyle() {
  //   // const rX = this.mousePX * 20;
  //   // const rY = this.mousePY * -20;
  //   return {
  //     // ATTENTION handled by sass since Vue fucks up
  //     // transform: `rotateY(${rX}deg) rotateX(${rY}deg)`
  //   };
  // },
  // cardBgTransform() {
  //   // const tX = this.mousePX * -20;
  //   // const tY = this.mousePY * -20;
  //   return {
  //     // ATTENTION handled by sass since Vue fucks up
  //     // transform: `translateX(${tX}px) translateY(${tY}px)`
  //   };
  // },
  // cardBgImage() {
  //   return {
  //     backgroundImage: `url(${this.dataImage})`
  //   };
  // }
  //},
  methods: {
    // handleMouseMove(event) {}, // Not used
    // handleMouseEnter(event) {}, // Not used
    // handleMouseLeave() {}, // Not used this method runs when you drag your mouse outside the card
    // handleMouseDown(event) {
    //   this.setMouseDownCoordinates(event);
    // },
    // setMouseDownCoordinates(event) {
    //   this.mouseDownCoor = event.clientX / event.clientY;
    //   // console.debug(this.mouseDownCoor)
    // },
    // getCurrentCoordinates(event) {
    //   // console.debug(event.clientX / event.clientY);
    //   return event.clientX / event.clientY;
    // },
    // click: function(event) {
    //   // this method runs when you lift your finger/mouse up
    //   if (this.mouseDownCoor == this.getCurrentCoordinates(event)) {
    //     // router.push("/preset/" + this.id + "/");
    //     // router.push(this.getLink());
    //   }
    // },
    getLink: function() {
      return "/preset/" + this.id + "/";
    }
  },
  filters: {
    capitalize: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  }
};
</script>
